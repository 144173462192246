<template>
  <mobile-screen :header="true" screen-class="levels-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="level-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("level", "edit-level", "working-hours") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button v-if="globalTimeFormat" class="clebex-item-range-cta">
                {{ displayTime(workingTimeStart) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-time" width="16" height="16" />
            </li>
            <li class="clebex-item-range to">
              <button v-if="globalTimeFormat" class="clebex-item-range-cta">
                {{ displayTime(workingTimeEnd) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="clebex-item-section-item overflow-hidden">
        <div class="clebex-item-content-wrapper">
          <select-time-picker-range
            :select-hour-fn="selectHour"
            :selected-hours="[workingTimeStart, workingTimeEnd]"
          />
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import SelectTimePickerRange from "@/components/global/SelectTimePickerRange";

export default {
  name: "ReceptionDeskWorkingHours",
  computed: {
    ...mapState("reception", ["workingTimeStart", "workingTimeEnd"]),
    ...mapGetters("settings", ["globalTimeFormat"])
  },
  methods: {
    displayTime(time) {
      if (!time) {
        return this.displayLabelName("global", "calendar", "time-slot");
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
    selectHour(hours) {
      this.$store.commit("reception/setWorkingTimeStart", hours[0], {
        root: true
      });
      this.$store.commit("reception/setWorkingTimeEnd", hours[1], {
        root: true
      });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectTimePickerRange
  }
};
</script>
